import { format } from "date-fns";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./CardPost.css";

interface CardPostProps {
  post: GatsbyTypes.CardPost_postFragment;
}
const CardPost: React.FC<CardPostProps> = (props) => {
  const { frontmatter, fields } = props.post;

  if (
    !frontmatter ||
    !frontmatter.thumbnail?.childImageSharp?.gatsbyImageData ||
    !frontmatter.title ||
    !frontmatter.createdAt ||
    !fields?.postPath
  ) {
    return null;
  }

  return (
    <Link to={fields.postPath} className={styles.container}>
      <div className={styles.thumbnailContainer}>
        <GatsbyImage
          image={frontmatter.thumbnail.childImageSharp?.gatsbyImageData}
          alt={frontmatter.title}
          objectFit="cover"
          objectPosition="50% 50%"
          className={styles.thumbnail}
          style={{
            position: "absolute",
          }}
        />
      </div>
      <div className={styles.title}>{frontmatter.title}</div>
      <div className={styles.caption}>
        {format(new Date(frontmatter.createdAt), "yyyy년 M월")}
      </div>
    </Link>
  );
};

export const CardPostFragment = graphql`
  fragment CardPost_post on MarkdownRemark {
    frontmatter {
      title
      createdAt
      thumbnail {
        childImageSharp {
          gatsbyImageData(
            width: 568
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    fields {
      postPath
    }
  }
`;

export default CardPost;
