import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { PROFILE_CAPTION, PROFILE_CV_URL, PROFILE_NAME } from "../constants";
import * as styles from "./MyProfile.css";

const MyProfile: React.FC = () => {
  return (
    <a
      className={styles.container}
      href={PROFILE_CV_URL}
      target="_blank"
      rel="noopener"
    >
      <div className={styles.avatarContainer}>
        <StaticImage
          src="../assets/img_my_profile.jpg"
          alt={PROFILE_NAME + " 프로필 사진"}
          objectFit="cover"
          objectPosition="50% 50%"
          className={styles.avatar}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.name}>{PROFILE_NAME}</div>
        <div className={styles.caption}>{PROFILE_CAPTION}</div>
      </div>
    </a>
  );
};

export default MyProfile;
