import React from "react";

import {
    PROFILE_CV_URL, PROFILE_FACEBOOK_URL, PROFILE_GITHUB_URL, PROFILE_NAME
} from "../constants";
import * as styles from "./Footer.css";

const Footer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.centered}>
        <div className={styles.copyright}>Designed by Tony / Written by Bonny</div>
        <div className={styles.links}>
          <a
            className={styles.link}
            href={PROFILE_CV_URL}
            target="_blank"
            rel="noopener"
          >
            Resume
          </a>
          <a
            className={styles.link}
            href={PROFILE_GITHUB_URL}
            target="_blank"
            rel="noopener"
          >
            GitHub
          </a>
          <a
            className={styles.link}
            href={PROFILE_FACEBOOK_URL}
            target="_blank"
            rel="noopener"
          >
            Facebook
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
