import React from "react";

import ImgLogoLabel from "../assets/img_logo_label.svg";
import { WEBSITE_TITLE } from "../constants";
import * as styles from "./LogoLabel.css";

const LogoLabel: React.FC = () => {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={ImgLogoLabel} alt={WEBSITE_TITLE} />
    </div>
  );
};

export default LogoLabel;
